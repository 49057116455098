/** @jsx jsx */

import { jsx, Heading, Flex, Label, Select, Box } from 'theme-ui';
import { PageRoot, ContentNode, Connection, Edge, ContentFrontmatter } from "../data/content"
import { IndexPageContainer, IndexPageRowsCompact, IndexPageRowsCompactFlexible } from "../components/indexes"
import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import { ResponsiveSplit } from "../components/atoms"
import { FeaturedCollection, FeaturedList, useLocalisedContent } from "../components/featured"
import { MobileContentBlock } from "../components/page"
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../components/seo';
import { PillarLogo } from '../images/pillars';
import { useTextDirection } from '../i18n';
import { colourMidpoint } from '../data/methods';
import { groupBy, keys } from 'lodash';
import { FilterStyling, PillarFiltersStyling } from '../components/joinform/elements';
import languages from '../data/languages';

const BlueprintIndexPage: PageRoot<{
  collections: Connection<ContentNode>,
  documents: Connection<ContentNode>,
}> = ({ data: { documents, collections }, location: { pathname } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const unintrusiveColour = "#6a6a6a"

  const fadedColour = colourMidpoint("#D8D4FF", "#3f27ff")

  const groupedArticlesByTopic = groupBy(documents.edges, (article) => {
    return article.node.frontmatter.topic?.id
  })
  const groupedArticlesByRegion = groupBy(documents.edges, (article) => {
    return article.node.frontmatter.region?.id
  })

  let availableAuthors: string[] = []

  documents.edges.forEach(function (article) {
    article.node.frontmatter.authors?.forEach(function (author) {
      if (!availableAuthors.includes(author?.id)) {
        availableAuthors.push(author?.id)
      }
    })
  })

  availableAuthors.sort()

  let groupedArticlesByAuthorDictionary: { [id: string]: Edge<ContentNode<ContentFrontmatter>>[]; } = {}

  availableAuthors.forEach(author => {
    groupedArticlesByAuthorDictionary[author] = []
  })

  documents.edges.forEach(article => {
    availableAuthors.forEach(author => {
      if (article.node.frontmatter.authors?.map(author => author?.id).includes(author)) {
        groupedArticlesByAuthorDictionary[author].push(article)
      }
    })
  })

  const groupedArticlesByAuthor = groupedArticlesByAuthorDictionary

  let availableLocales: string[] = []

  documents.edges.forEach(function (article) {
    article.node.fields.localePaths?.forEach(function (localePath) {
      if (!availableLocales.includes(localePath.locale)) {
        availableLocales.push(localePath.locale)
      }
    })
  })

  availableLocales.sort()

  let groupedArticlesByLanguageDictionary: { [id: string]: Edge<ContentNode<ContentFrontmatter>>[]; } = {}

  availableLocales.forEach(locale => {
    groupedArticlesByLanguageDictionary[locale] = []
  })

  documents.edges.forEach(article => {
    availableLocales.forEach(locale => {
      if (article.node.fields.localePaths?.map(localePath => localePath.locale).includes(locale)) {
        groupedArticlesByLanguageDictionary[locale].push(article)
      }
    })
  })

  const groupedArticlesByLanguage = groupedArticlesByLanguageDictionary

  //const groupedArticlesByLanguage = groupBy(content.edges, (article) => {
  //  return article.node.fields.localePaths?.map(localePath => localePath.locale).some(locale => availableLocales.includes(locale))
  //})

  const [state, setState] = useState({
    filters: {
      selectedTopicId: "",
      selectedRegionId: "",
      selectedAuthorId: "",
      selectedLocale: ""
    }
  })

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedTopicId: event.target.value }
    setState({ filters })
  }

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedRegionId: event.target.value }
    setState({ filters })
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedLocale: event.target.value }
    setState({ filters })
  }

  const handleAuthorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedAuthorId: event.target.value }
    setState({ filters })
  }

  const showArticle = (node: ContentNode) => {
    const selectedAuthorId = state.filters.selectedAuthorId
    const selectedTopicId = state.filters.selectedTopicId
    const selectedRegionId = state.filters.selectedRegionId
    const selectedLocale = state.filters.selectedLocale

    let valid = true

    if (selectedTopicId?.length) {
      if (node.frontmatter.topic?.id != selectedTopicId) {
        valid = false
      } else if (!node.frontmatter.topic) {
        valid = false
      }
    }

    if (selectedRegionId?.length) {
      if (node.frontmatter.region?.id != selectedRegionId) {
        valid = false
      } else if (!node.frontmatter.region) {
        valid = false
      }
    }

    if (selectedLocale?.length) {
      if (!node.fields.localePaths?.map(localePath => localePath.locale).includes(selectedLocale)) {
        valid = false
      } else if (node.fields.localePaths === undefined) {
        valid = false
      }
    }

    if (selectedAuthorId?.length) {
      if (!node.frontmatter.authors?.map(author => author?.id).includes(selectedAuthorId)) {
        valid = false
      } else if (node.frontmatter.authors === undefined) {
        valid = false
      }
    }

    return valid
  }

  const toggleFilters = () => {
    try {
      const filterContainer = document.getElementById("filterContainer")

      if (filterContainer?.classList.contains("openFilter")) {
        filterContainer.classList.remove("openFilter")
      } else {
        filterContainer?.classList.add("openFilter")
      }
    } catch (e) {

    }
  }

  return (
    <IndexPageContainer
      title={t(`Blueprint`)}
      bg="blueprintHexLight"
      color="blueprintHex"
      isRtl={dir.isRtl}
      heading={
        <Fragment>
          <p><Trans>We are designing a policy blueprint to transform the institutions that impact our lives, our communities, and the planet.</Trans></p>
          <p><Trans>The Progressive International convenes activists, thinkers, and practitioners to develop the principles and policies of a progressive international order.</Trans></p>
          <p><Trans>Explore the inaugural collection of the Blueprint pillar below.</Trans></p>
        </Fragment>
      }
      icon={
        <PillarLogo sx={{ color: "blueprintHex", size: [40, 70, 80, 100] }} type={'blueprint'} />
      }

      extra={
        <Flex id="filterContainer" sx={PillarFiltersStyling("blueprint", fadedColour, unintrusiveColour, dir)}>
          <Label>
            FILTERS
            <div onClick={toggleFilters}><Select disabled></Select></div>
          </Label>
          <Box pr={["0px", "6px", "42px"]} sx={{ flex: ['100%', '25%', '25%'] }}>
            <Select
              name='topic'
              onChange={handleTopicChange}
              sx={FilterStyling(state.filters.selectedTopicId, "blueprint", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Topic')}</option>
              {keys(groupedArticlesByTopic).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {useLocalisedContent(groupedArticlesByTopic[id][0].node.frontmatter.topic)?.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "2px", "14px"]} pr={["0px", "4px", "28px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='region'
              onChange={handleRegionChange}
              sx={FilterStyling(state.filters.selectedRegionId, "blueprint", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Region')}</option>
              {keys(groupedArticlesByRegion).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {useLocalisedContent(groupedArticlesByRegion[id][0].node.frontmatter.region)?.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "4px", "28px"]} pr={["0px", "2px", "14px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='language'
              onChange={handleLanguageChange}
              sx={FilterStyling(state.filters.selectedLocale, "blueprint", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Language')}</option>
              {keys(groupedArticlesByLanguage).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {languages.find(l => l.isoName == id)?.nativeName}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "6px", "42px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='author'
              onChange={handleAuthorChange}
              sx={FilterStyling(state.filters.selectedAuthorId, "blueprint", fadedColour, unintrusiveColour, dir, true)}
            >
              <option value="">{t('Author')}</option>
              {keys(groupedArticlesByAuthor).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {groupedArticlesByAuthor[id][0].node.frontmatter.authors?.filter(author => author?.id == id)[0]?.frontmatter.title}
                </option>
              )).sort()}
            </Select>
          </Box>
        </Flex >
      }
    >
      <SEO
        path={pathname}
        title={'Blueprint'}
        description={'The Blueprint brings together thinkers and practitioners to develop the principles and policies of a progressive international order.'}
      />
      <ResponsiveSplit isRtl={dir.isRtl}>
        <div>
          <MobileContentBlock>
            <Heading variant='headings.2' sx={{ color: "blueprintHex", mb: 3 }}>
              <Trans>Latest</Trans>
            </Heading>
          </MobileContentBlock>

          <IndexPageRowsCompactFlexible content={documents.edges.filter(({ node }) => (
            showArticle(node)
          ))} />
        </div>

        <MobileContentBlock>
          <Heading variant='headings.2' sx={{ color: "blueprintHex", mb: 3 }}>
            <Trans>Projects</Trans>
          </Heading>

          <FeaturedList>
            {collections.edges.map(({ node }) => <FeaturedCollection key={node.id} node={node} />)}
          </FeaturedList>
        </MobileContentBlock>
      </ResponsiveSplit>
    </IndexPageContainer >
  )
}

export const pageQuery = graphql`
  query BlueprintIndexPage {
    documents: allMarkdownRemark(
      filter: { fields: { type: { eq: "blueprint-documents" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    collections: allMarkdownRemark(
      filter: { fields: { type: { eq: "blueprint-collections" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default BlueprintIndexPage
